@import url('colors.css');

div.about-page {
    width: 80%;
    display: grid;
    margin-left: 10%;
    margin-right: 10%;
    grid-gap: 8px;
}

section.about-page {
    background-color: var(--main-card-bg-color);
    border-radius: 24px;
    padding: 12px;
}

p.about-page.section-title {
    font-size: x-large;
    margin: 0;
}

p.about-page.section-description {
    font-size: large;
}

ul.about-page {
    font-size: large;
}

a.about-page {
    text-decoration: none;
    background-color: var(--accent-color-three);
    color: var(--accent-color-four);
}
