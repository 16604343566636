@import url('colors.css');

.Question-tile {
  background-color: var(--main-card-bg-color);
  border-color: var(--main-card-border-color);
  border-style: groove;
  padding: 12px;
  margin-bottom: 2%;
  border-radius: 25px;
  box-shadow: 0 4px 8px 0 var(--main-border-shadow-color);
  transition: box-shadow 0.3s;
}

.Questions {
  display: block;
}

.Question-tile:hover {
  box-shadow: 0 8px 16px 0 var(--main-border-shadow-color);
}

form.createQuestionForm {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
  font-family: roboto, karma, segoe ui, sans-serif;
  padding-top: 16px;
}

div.createQuestionForm-wrapper {
  width: 50vw;
  margin-left: 25vw;
  margin-right: 25vw;
  background-color: var(--main-card-bg-color);
  border-radius: 8px;
  padding: 16px;
}

label.createQuestionForm {
  font-size: large;
  font-weight: bold;
}

p.question-subtitle,
p.question-description {
    margin: 0;
    font-size: medium;
}

li.question-possibility {
  white-space: pre-line;
  word-wrap: break-word;
}

textarea.createQuestionForm {
  overflow: auto;
  height: 60px;
}

button.createQuestionForm {
  cursor: pointer;
  background-color: var(--main-title-color);
  border-radius: 10px;
  border-color: var(--main-border-color);
  color: var(--main-card-bg-color);
  font-size: medium;
}

button.createQuestionForm[type=submit] {
  width: 100%;
  font-weight: bold;
}

button.createQuestionForm:hover {
  background-color: var(--main-card-bg-color);
  color: var(--main-title-color);
}

div.input-possibilities {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr;
  padding-top: 10px;
  padding-bottom: 10px;
}

div.createQuestionForm.possibility {
  display: grid;
  grid-gap: 8px;
}

div.question-header {
  display: grid;
  grid-gap: 8px;
  column-width: 100px;
  grid-template-columns: repeat(5, fit-content(100px));
}

p.question-topic {
  background-color: var(--main-title-color);
  color: var(--main-border-color);
  word-wrap: break-word;
  white-space: pre-line;
  border-radius: 5px;
  padding: 2px;
}

input.createQuestionForm.possibility {
  grid-column: auto;
  grid-template-columns: auto;
}

button.createQuestionForm.possibility {
  grid-column: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

button.createQuestionForm.submit {
  height: 50px;
}

button.createQuestionForm.addNewPossibility {
  width: 50px;
  justify-self: right;
  padding: 8px;
  margin-top: 8px;
}

button.createQuestionForm.removeExistingPossibility {
  width: 50px;
  justify-self: right;
  padding-top: 10px;
  padding-bottom: 10px;
}

textarea.createQuestionForm,
input.createQuestionForm {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  font-size: medium;
}

.forecast-section-divider {
  font-weight: bolder;
  margin: 24px 12px;
  font-size: larger;
}

a.question-tile.go-to-forecast,
a.question-tile.go-to-question {
  display: block;
  text-decoration: none;
  color: var(--main-title-color);
  word-wrap: break-word;
}

div.question-title,
a.question-tile.go-to-question {
  font-size: large;
}

a.question-tile.go-to-forecast {
  width: max-content;
  background-color: var(--main-title-color);
  color: var(--main-bg-color);
  padding: 8px;
  border-radius: 12px;
}

li.question-possibility.winner {
  color: var(--main-title-color);
  font-weight: bolder;
}

div.question-subtitle {
  padding-bottom: 8px;
}

p.outcome-selection {
  font-size: large;
}

button.resolve-outcome-option {
  background-color: var(--main-title-color);
  color: var(--main-bg-color);
  padding: 8px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  margin-top: 8px;
}

input, textarea {
  border: 2px solid var(--main-border-color);
}

input:focus {
  border: 2px solid var(--main-border-color);
}

a.creator-link {
  text-decoration: none;
  color: var(--main-title-color);
  font-size: medium;
}

div.possibility {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 6fr 1fr;
}

input.possibility {
  width: 100%;
}

div.full-question {
  margin-left: 25vw;
  width: 50vw;
  margin-right: 25vw;
}

@media screen and (max-width: 768px) {
  div.createQuestionForm {
    width: auto;
    padding: 0;
  }

  .Question-tile {
    margin: 0;
    width: auto;
    padding: 4px;
    margin: 12px;
  }

  input.createQuestionForm,
  textarea.createQuestionForm {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 0;
    padding-right: 0;
  }

  div.full-question {
    margin-left: 0;
    width: auto;
    margin-right: 0;
  }

  div.createQuestionForm-wrapper {
    margin: 12px;
    width: auto;
  }
}
