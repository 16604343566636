@import url('colors.css');

form.createForecast {
    display: grid;
    grid-gap: 16px;
    padding-top: 12px;
}

button.createForecast {
    cursor: pointer;
    background-color: var(--main-title-color);
    border-radius: 10px;
    border-color: var(--main-border-color);
    color: var(--main-card-bg-color);
    font-size: medium;
}
  
button.createForecast:hover {
    background-color: var(--main-card-bg-color);
    color: var(--main-title-color);
}

div.createForecast.question-possibilities {
    display: grid;
    grid-gap: 8px;
}

div.createForecast.question-possibility {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

p.question-possibility
{
    word-wrap: break-word;
    margin: 0;
}

div.question-possibility-forecast {
  border-top: 1px solid black;
  word-wrap: break-word;
}

div.question-possibilities-grid {
    display: grid;
    grid-template-columns: 4fr 1fr;
	grid-gap: 4px;
}

div.createForecast.question-possibility-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 3px double black;
}

div.createForecast.question-possibility-footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-top: 3px dotted black;
    padding-top: 10px;
}

div.createForecast.input-header,
label.createForecast.input-header {
    font-weight: bolder;
}

textarea.createForecast.explanation {
    height: 80px;
}

button.createForecast {
    padding: 8px;
}

a.forecast-go-to-question {
    display: block;
    text-decoration: none;
    color: var(--main-title-color);
}

input.createForecast {
    font-size: 100%;
}

@media (min-width: 768px) {
    div.createForecast.question-possibilities {
        width: 500px;
    }

    button.createForecast {
        width: 224px;
    }

    div.Forecasts {
        margin-right: 25vw;
        margin-left: 25vw;
        width: 50vw;
    }
}
