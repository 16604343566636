@import url('colors.css');

form.signUpForm {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    font-family: roboto, karma, segoe ui, sans-serif;
    padding: 8px;
}

label.signUpForm {
    justify-self: left;
}

button.signUpForm {
    cursor: pointer;
    background-color: var(--main-title-color);
    border-radius: 10px;
    border-color: var(--main-border-color);
    color: var(--main-card-bg-color);
    font-size: medium;
}

button.signUpForm:hover {
    background-color: var(--main-card-bg-color);
    color: var(--main-title-color);
}

label.signUpForm,
button.signUpForm,
input.signUpForm {
    padding: 8px;
    font-size: 100%;
}

@media (min-width: 768px) {
    form.signUpForm {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 16px;
        font-family: roboto, karma, segoe ui, sans-serif;
    }
    
    label.signUpForm {
        grid-column: 1/2;
        justify-self: right;
    }
    
    input.signUpForm {
        grid-column: 2/3;
    }
    
    button.signUpForm {
        grid-column: 2/3;
    }
}