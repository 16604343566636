:root {
  --main-bg-color: #f4f5f5;
  --header-footer-color:#f0f0f0; /* #b7bcc0;*/
  --main-text-color: #000;
  --main-title-color: #008dd0;
  --main-border-shadow-color: rgba(0,0,0,0.2);
  --main-border-color: #dbdbdb;
  --main-card-bg-color: #e9e9e9;
  --main-card-border-color: #0000;  
  --accent-color-one: #35a7c7;
  --accent-color-two: #6fc6df;
  --accent-color-three: #bbd1fa;
  --accent-color-four: #2269ec;
  --gentle-error: #df2a23;
  --gentle-success: #04aa5d;
}
