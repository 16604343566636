@import url('colors.css');

div.landing-page {
    display: grid;
    margin-left: 25vw;
    margin-right: 25vw;
    width: 50vw;
    grid-gap: 8px;
}

section.landing-page {
    background-color: var(--main-card-bg-color);
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;
    border-radius: 24px;
}

section.landing-page.call-to-action {
    background-color: var(--accent-color-two);
    text-align: center;
}

section.landing-page.title {
    padding: 20px;
    font-weight: bold;
}

section.landing-page.call-to-action,
section.landing-page.description {
    padding: 20px;
}

a.landing-page.call-to-action {
    text-decoration: none;
    color: var(--main-bg-color);
    font-size: large;
}

a.landing-page.description {
    text-decoration: none;
    color: var(--main-title-color);
    font-size: large;
}

p.landing-page.section-title {
    font-size: large;
    margin: 0;
}

p.landing-page.section-description {
    font-size: large;
    margin: 0;
}

ul.landing-page {
    font-size: 20px;
}

@media screen and (max-width: 768px) {
    div.landing-page {
        margin-left: 5vw;
        margin-right: 5vw;
        width: 90vw;
    }   
}
