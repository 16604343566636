@import url('colors.css');

label.description-preview {
    font-size: large;
    font-weight: bold;
}

textarea.markdown-text-input {
    width: 100%;
}

div.preview {
    padding-top: 12px;
}