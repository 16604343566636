@import url('colors.css');

form.loginForm {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    font-family: roboto, karma, segoe ui, sans-serif;
    padding: 8px;
}

label.loginForm {
    justify-self: left;
}

button.loginForm {
    cursor: pointer;
    background-color: var(--main-title-color);
    border-radius: 10px;
    border-color: var(--main-border-color);
    color: var(--main-card-bg-color);
    font-size: medium;
}

button.loginForm:hover {
    background-color: var(--main-card-bg-color);
    color: var(--main-title-color);
}

label.loginForm,
button.loginForm,
input.loginForm {
    padding: 8px;
    font-size: 100%;
}

a.loginForm {
    text-decoration: none;
    color: var(--main-title-color);
}

@media (min-width: 768px) {
    form.loginForm {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 16px;
        font-family: roboto, karma, segoe ui, sans-serif;
    }
    
    label.loginForm {
        grid-column: 1/2;
        justify-self: right;
    }
    
    a.loginForm,
    input.loginForm {
        grid-column: 2/3;
    }
    
    button.loginForm {
        grid-column: 2/3;
    }
}
