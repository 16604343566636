@import url('AboutPage.css');
@import url('colors.css');
@import url('Forecast.css');
@import url('index.css');
@import url('LandingPage.css');
@import url('Login.css');
@import url('Profile.css');
@import url('Question.css');
@import url('SignUp.css');
@import url('Group.css');

html, body {
  height: 100%;
}

#root {
  height: 100%;
}

.App {
  background-color: var(--main-bg-color);
  display: grid;
  grid-gap: 20px;
  grid-template-rows: auto 1fr auto;
  min-height: 100%;
}

.header {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  grid-template-areas: "App-header-logo" "App-header-title" "App-header-settings";
  background-color: var(--header-footer-color);
  border-bottom: 1px solid var(--main-border-color);
  width: 100vw;
}

.footer {
  background-color: var(--header-footer-color);
  grid-row: 3/4;
  width: 100vw;
}

.header-logo {
  height: 6vmin;
  pointer-events: none;
  padding: 8px;
  display: flex;
  justify-content: right;
  align-items: center;
}

.header-title {
  justify-self: left;
  align-items: center;
  display: flex;
  font-size: xx-large;
  line-height: 100%;
  color: var(--main-title-color);
  font-weight: 400;
}

a.header-navigation,
button.header-navigation {
  background: none;
  font-size: calc(8px + 2vmin);
}

a {
  color: var(--main-title-color);
  text-decoration: underline
}

a.success-message,
a.header-title,
a.header-title:hover,
a.header-title:active,
a.header-title:visited,
a.header-navigation,
a.footer-menu {
  text-decoration: none;
}

nav.header-navigation ul {
  display: none;
  margin-right: 32px;
  padding-left: 12px;
}

div.header-navigation {
  grid-column-start: 1;
  grid-column-end: 4;
}

nav ul {
  display: grid;
  grid-gap: 12px;
  list-style: none;
  text-align: left;
}

div.Error,
div.Loading {
  text-align: center;
  font-weight: bolder;
}

button.expand-menu {
  display: block;
}

div.success-message,
div.error-message {
  text-align: center;
  font-size: medium;
  font-weight: 400;
}

.gentle-error-message {
  color: var(--gentle-error);
}

.expand-menu {
  transition: background-color 0.5s ease;
  background-color: var(--accent-color-one);
  color: var(--main-bg-color);
  font-size: medium;
  border: none;
}

.expand-menu:hover {
  color: var(--accent-color-one);
  background-color: var(--main-bg-color);
}

button {
  outline: none;
  border: none;
}

div.no-padding {
  padding: 0;
}

div.auto-width {
  width: auto;
}

div.happy {
  color: var(--gentle-success);
}

div.sad {
  color: var(--gentle-error);
}

p {
  margin: 0;
}

h1.section-title {
  font-size: x-large;
  text-align: left;
}

h1.form-title {
  font-size: x-large;
  text-align: left;
  margin: 0;
  padding-bottom: 16px;
}

h1 {
  font-weight: bold;
}

li.nav-menu {
  display: flex;
  justify-content: center;
  align-items: center;
}

li.nav-menu.header-navigation {
  justify-content: left;
  font-weight: bold;
}

div.minimal {
  margin: 12px;
}

ul.footer-menu {
  text-align: center;
  grid-template-columns: repeat(2, 1fr);
}

@media (min-width: 768px) {
  nav.header-navigation ul {
    display: grid;
    grid-auto-flow: column;
  }

  div.header-navigation {
    grid-column-start: auto;
    grid-column-end: auto;
  }

  button.expand-menu {
    display: none;
  }

  a.header-navigation,
  button.header-navigation {
    font-size: medium;
  }

  a.footer-menu {
    font-size: medium;
  }

  div.homepage.content {
    margin-left: 25vw;
    margin-right: 25vw;
  }

  div.minimal {
    margin-left: 0;
    margin-right: 0;
  }
}
