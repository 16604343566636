@import url('colors.css');

div.profile-page {
    width: 50vw;
    padding-left: 25vw;
}

div.profile-summary {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 8px;
    background-color: var(--accent-color-two);
    border: 1px solid #fff;
    padding: 20px;
    border-radius: 5px;
}

div.profile-summary-header {
    grid-column-end: 3;
    grid-column-start: 1;
    font-weight: bold;
}

div.profile-summary-element {
    border-top: 1px solid #000;
}

button.profile-section-title {
    padding: 12px;
    font-size: large;
    border-radius: 12px;
    width: 100%;
    cursor: pointer;
    text-align: left;
    margin-top: 8px;
    margin-bottom: 8px;
}

button.profile-section-title:hover {
  box-shadow: inset 0 -3.25em 0 0 var(--accent-color-two);
}

span.profile-section-group-summary-element {
    font-weight: bold;
}

a.group-about {
    border-radius: 5px;
    margin: 8px;
    padding: 4px;
}

button.profile-page.logout {
    border: none;
    margin-top: 8px;
    background-color: var(--accent-color-two);
    color: var(--main-card-bg-color);
    cursor: pointer;
    font-size: large;
    border-radius: 4px;
    font-weight: bold;
    padding: 8px;
}

@media screen and (max-width: 768px) {
    div.profile-page {
        width: auto;
        padding: 0;
    }
  }