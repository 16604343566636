@import url('colors.css');

form.createGroupForm {
  display: grid;
  grid-template-columns: 1fr;
  font-family: roboto, karma, segoe ui, sans-serif;
  grid-gap: 12px;
}

label.createGroupForm {
  justify-self: left;
}

p.question-subtitle,
p.question-description {
    margin: 0;
}

textarea.createGroupForm {
  overflow: auto;
  height: 60px;
}

button.createGroupForm, button.exit-group {
  cursor: pointer;
  background-color: var(--main-title-color);
  border-radius: 10px;
  border-color: var(--main-border-color);
  color: var(--main-card-bg-color);
  font-size: medium;
}

button.exit-group {
  padding: 4px;
}

button.createGroupForm:hover {
  background-color: var(--main-card-bg-color);
  color: var(--main-title-color);
}

input[type=checkbox].createGroupForm {
  width: 20px;
  height: 20px;
}

button[type=submit].createGroupForm {
  height: 50px;
}

label.createGroupForm,
textarea.createGroupForm,
input.createGroupForm {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: large;
  width: 100%;
}

label.createGroupForm {
  font-weight: bold;
}

input {
  border: 2px solid var(--main-border-color);
}

input:focus {
  border: 2px solid var(--main-border-color);
}

div.group-page-summary {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
}

div.group-page.description {
    font-size: large;    
}

a.profile-summary-element {
    text-decoration: none;
    color: var(--accent-color-four);
}

button.createGroupForm[type=submit] {
  width: 100%;
  font-weight: bold;
}

div.createGroupForm-wrapper {
  background-color: var(--main-card-bg-color);
  border-radius: 8px;
  padding: 16px;
  margin: 12px;
}

div.group-page.wrapper {
  padding: 16px;
}

@media (min-width: 768px) {

  div.group-page.wrapper,
  div.createGroupForm-wrapper {
    width: 50vw;
    margin-left: 25vw;
    margin-right: 25vw;
  }

}
